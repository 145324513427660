.chatTab {
	/* border: 1px solid red; */
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	width: 100vw;
	height: calc(100vh - 65px);
	background-color: #fff;
	overflow: hidden;
}

/*** CHAT INBOX - NEW MESSAGE VIEW ***/
.chatTab__newMessage {
	border-right: 1px solid var(--line-grey);
	min-width: 300px;
	width: 450px;
	max-width: 450px;
}

.chatTab__newMessage__header--flex {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;
	height: auto;
}

.chatTab__newMessage__header {
	display: flex;
	align-items: center;
}

.chatTab__newMessage__header > p {
	font-size: 1.1em;
	font-weight: 500;
}

/*********************************


/*** CHAT INBOX - DEFAULT VIEW ***/

.chatTab__inbox {
	border-right: 1px solid var(--line-grey);
	min-width: 300px;
	width: 480px;
	max-width: 480px;

	/* height: calc(100vh - 65px); */
}

.chatTab__chatbox {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.chatTab__inbox__header--flex {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0.5rem 1rem;
	height: auto;
}

.chatTab__inbox__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.chatTab__inbox__header > p {
	font-size: 1.5em;
	font-weight: 500;
}

.chatTab__inbox__headerBtnWrapper {
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
}
.chatTab__inbox__headerBtnWrapper:hover {
	background: rgba(0, 0, 0, 0.04);
}

.searchInput--wrapper {
	display: relative;
	width: 100%;
	height: 100%;
}

.searchInput--clear {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}
.searchInput--clear > img {
	width: 27px;
}
.searchInput--clear > img:hover {
	background: rgba(0, 0, 0, 0.06);
	border-radius: 50%;
}

.chatTab__inbox__form {
	color: #555;
	background-color: var(--Grey6);
	display: flex;
	padding: 2px;
	border: 1px solid currentColor;
	border-radius: 5px;
}

.chatTab__inbox__options {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	gap: 0.5rem;
	padding: 5px 0px;
}

.chatTab__inbox__options .MuiFormControl-root {
	width: 100%;
}
.chatTab__inbox__options
	.MuiFormControl-root.MuiSelect-outlined.MuiSelect-outlined {
	padding-right: 0px;
}

.chatTab__inbox__options .MuiFormControl-root.MuiSelect-selectMenu {
	height: 0px;
	min-height: 0px;
}
/* ********** */
.chatTab__newMessage__searchItemList {
	/* padding: 1rem 0; */
	display: flex;
	flex-direction: column;
	height: calc(100vh - 120px - 65px);
	overflow: auto;
}

.chat__queueItem__cardHeader {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.chat__queueItem__cardName {
	text-transform: capitalize;
}
.chatTab__newMessage__searchItem {
	display: flex;
	align-items: center;
	font-size: 1rem;
	gap: 0.5rem;
	cursor: pointer;
	border-bottom: 1px solid var(--line-grey);
	padding: 0.8rem 1rem;
}

.chatTab__newMessage__searchItem:hover {
	background: var(--Grey6);
}
.chatTab__newMessage__searchItem.selected {
	background: var(--Grey6);
}
.chat__queueItem__cardDotGrey {
	width: 5px;
	height: 5px;
	background: var(--Grey5);
	border-radius: 50%;
}

.chat__queueItem__cardRole,
.chat__queueItem__cardStatus {
	font-size: 0.8em;
	padding: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.4rem;
	border-radius: 4px;
	width: fit-content;
}

.role--admin {
	color: #ff9b42;
	background: #ffead7;
}
.role--frontdesk {
	color: #27ae60;
	background: #d3f1e0;
}
.role--nurse {
	color: #fc579c;
	background: #ffe5f0;
}
.role--physician {
	color: #7b3efc;
	background: #e7d8ff;
}
.role--patient {
	color: var(--Grey2);
}

.status-optOut {
	color: #f57850;
	background: #ffeae3;
}

.chat__queueItem__cardCircle--wrapper {
	/* display: flex;
  gap: 0.5rem;
  align-items: center; */
}

/* ************* */

/****/
.chatTab--flex {
	display: flex;
	gap: 1rem;
}
.chatTab__inbox__options--Switch {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	padding: 2px;
	height: 40px;
	border: 1px solid hsl(0, 0%, 80%);
	width: 100%;
}

.chatTab__inbox__options--Switch .switch {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 6px;
	font-size: 0.9rem;
	transition: 0.5s ease all;
	cursor: pointer;
	color: var(--color-grey-900);
}
.switch.switch__open {
	color: var(--Grey3);
}
.switch.switch__open,
.switch.switch__open.active {
	border-radius: 4px 0px 0px 4px;
}

.switch.switch__closed {
	color: var(--Grey3);
}
.switch.switch__closed,
.switch.switch__closed.active {
	border-radius: 0px 4px 4px 0px;
}

.chatTab__inbox__options--Switch > .switch.switch__open.active {
	background: var(--primary-green);
	color: #fff;
}

#chatTab__inbox__selectPhysician {
	font-size: 1rem;
	width: 100%;
}

.react-select-inner__control {
	box-shadow: 0 0 0 1px var(--primary-green);
}
.react-select-inner__control--is-focused {
	border-color: var(--primary-green) !important;
}

/****/

input[type='search'] {
	border: none;
	background: transparent;
	margin: 0;
	padding: 7px 8px;
	font-size: 14px;
	color: inherit;
	border: 1px solid transparent;
	border-radius: inherit;
	color: black;
}

input[type='search']::placeholder {
	color: var(--Grey3);
}

input[type='search']:focus {
	box-shadow: 0 0 3px 0 var(--Secondary-green);
	border-color: var(--Secondary-green);
	outline: none;
}

.chatTab__inbox__form,
.chatTab__inbox__formInput {
	border: none;
	padding: 0;
}

.chatTab__inbox__formInput {
	width: 100%;
	padding: 15px 4px 15px 40px;
	background: transparent
		url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
		no-repeat 13px center;
}

/* *** Chat Inbox ChatList styles *** */
.participant__chat__listItems {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	justify-content: flex-end;
}
.chat__overlay__queue {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 100px);
	flex: 0 0 288px;
	width: 288px;
	min-width: 0;
	background: rgb(255, 255, 255);
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	transform: translateY(0);
	transition-duration: 167ms;
	animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1);
	border-radius: 6px 6px 0px 0px;
}

.chat__overlay__queue--isMinimised {
	transform: translateY(100%) translateY(-48px);
}

.chat__overlay__queue__header {
	display: flex;
	justify-content: space-between;
	min-height: 48px;
	align-items: center;
	padding: 0 1rem;
	border-bottom: 1px solid var(--line-grey);
	cursor: pointer;

	border-radius: 6px 6px 0px 0px;
}

.chat__overlay__headerHeading {
	font-weight: 500;
}

.chat__overlay__headerMinBtn {
	transform: rotate(180deg);
}

.chat__overlay__headerMinBtn--clicked {
	transform: rotate(0deg);
}

/* ** styles for chat queue items ** */
.chatTab__chatList {
	border-top: 1px solid var(--Grey5);
	height: calc(100vh - 65px - 165px);
	/* height: 100%; */
	overflow: auto;
}
.chat__queueItems {
	overflow: auto;
}

.chat__queueItem:hover {
	background: var(--Grey6);
}

.chat__queueItem.selected {
	background: var(--Grey5);
}

.chat__queueItem__card {
	cursor: pointer;
	padding: 0.8rem;
	display: grid;
	grid-template-columns: 40px auto;
	gap: 0.5rem;
	border-bottom: 1px solid var(--Grey5);
}

.chat__queueItem__cardContent {
	display: flex;
	flex-direction: column;
}

.chat__queueItem__cardContent--header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.chat--flex {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	flex-wrap: wrap;
}

.chat__queueItem__cardCircle--flexWrapper {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.chat__queueItem__cardContent--name,
.chat__queueItem__cardContent--dob,
.chat__queueItem__cardContent--mrn {
	font-size: 1em;
	text-transform: capitalize;
}

.chat__queueItem__cardContent--name {
	color: var(--color-grey-900);
}

.grey--dot {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: var(--color-grey-400);
}

.chat__queueItem__cardContent--newTag {
	width: 35px;
	border-radius: 120px;
}
.chat__queueItem__cardContent--date {
	font-size: 0.7em;
	color: var(--color-grey-800);
	display: flex;
	align-items: center;
	gap: 0.2rem;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.chat__queueItem__cardContent--msgPreview,
.chat__queueItem__cardContent--clinicName,
.chat__queueItem__cardContent--msgPreviewUnread {
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	white-space: nowrap;
	font-size: 0.8em;
	width: 250px;
	color: var(--color-grey-500);
}

.chat__queueItem__cardContent--msgPreview,
.chat__queueItem__cardContent--msgPreviewUnread {
	margin-top: 4px;
	font-size: 0.9rem;
	color: var(--color-grey-900);
}

.chat__queueItem__cardContent--msgPreviewUnread {
	font-weight: 500;
}

.chat__queueItem__cardCircle {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	color: var(--Secondary-green);
	font-size: 0.9em;
	background: var(--Secondary-green--light);
	border-radius: 50%;
	position: relative;
}

.chat__queueItem__cardCircle.user--doctor {
	background: #e7d8ff;
}
.chat__queueItem__cardCircle.user--nurse {
	background: #ffe5f0;
}
.chat__queueItem__cardCircle.user--frontdesk {
	background: #d3f1e0;
}

.chat__statusIndicator {
	width: 10px;
	height: 10px;
	background: #27ae60;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: 0;
}

/* ***************** */
.select-dropdown,
.select-dropdown * {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}
.select-dropdown {
	position: relative;
	background-color: #e6e6e6;
	border-radius: 4px;
}
.select-dropdown select {
	font-size: 1rem;
	font-weight: normal;
	max-width: 100%;
	padding: 8px 24px 8px 10px;
	border: none;
	background-color: transparent;
	background: white;
	border: 1.5px solid var(--color-grey-200);
	border-radius: 4px;
	appearance: none;
	width: 100%;
	z-index: 10;
	color: #014f86;
}
.select-dropdown select:active,
.select-dropdown select:focus {
	outline: none;
	box-shadow: none;
}
.select-dropdown:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 8px;
	width: 0px;
	height: 0px;
	margin-top: -2px;
	border-top: 5px solid #014f86;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	z-index: 10;
}

/* ***** */

/* *** CHAT MESSAGE BOX ***  */
.chatTab__chatbox__header {
	display: flex;
	gap: 1rem;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid var(--line-grey);
	height: 32px;
}

.participant__chat__list {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	overflow-y: auto;
	height: 100%;
	padding: 0.5rem;
	/* padding-bottom: 10rem; */
	/* height: 80%; */
	/* height: calc(100% - 70px - 70px); */
}

.participant__chatboxCurrentDate {
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	color: var(--Grey2);
}

.participant__chat--dotGrey {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: var(--color-grey-400);
}

.participant__chatbox {
	display: flex;
}

.chatTab__chatbox__circleLoader {
	display: flex;
	justify-content: center;
	padding: 1rem 0;
}

.participant__chatbox--wrapper {
	display: flex;
	gap: 1rem;
}

.participant__chatbox--wrapper--flexEnd {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.participant__chatboxHeader {
	display: flex;
	gap: 0.8rem;
	align-items: center;
	justify-content: flex-end;
}

.participant__chatboxTitle {
	font-weight: 500;
	font-size: 0.85em;
	color: var(--color-grey-800);
	text-align: right;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 6px;
	justify-content: flex-end;
}

.participant__chatboxTime {
	color: var(--color-grey-500);
	font-weight: 500;
	font-size: 0.85em;
}

.participant__chatBottom--chatTab {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	border-radius: 6px;
}

.participant__chatboxMessage--wrapper {
	word-break: break-all;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
}

.participant__chatboxTime--side {
	font-size: 0.9em;
	/* font-weight: 500; */
	color: var(--color-grey-600);
}

.participant__chatbox--doctor .participant__chatboxTime {
	color: #fff;
}

.participant__chatboxMessage {
	padding: 10px 16px;
	max-width: 564px; /* CHANGED*/
	border-radius: 8px 8px 0px 8px;
	color: #fff;
	display: flex;
	align-items: center;

	background: var(--color-grey-700);
	text-align: left;
	width: fit-content;
	word-break: break-word;
	font-size: 0.9em;

	justify-content: flex-end;
	/* align-items: flex-end; */
	flex-wrap: wrap;
	column-gap: 0.5rem;
	row-gap: 0.1rem;
}

.participant__chatbox--doctor .participant__chatboxMessage > p {
	text-align: left;
}

.participant__chatboxMessage--bottom {
	display: flex;
	gap: 0.3rem;
	justify-content: flex-end;
}
.participant__chatboxMessage--patient > .participant__chatboxMessage--bottom {
	justify-content: flex-start;
}

.msg-read-icon {
	width: 20px;
}
.participant__chatboxMessage--patient {
	border-radius: 0px 8px 8px 8px;
}

.participant__chatbox--doctor {
	justify-content: flex-end;
	margin-left: 1rem;
}

.participant__chatbox--patient {
	justify-content: flex-start;
	margin-right: 1rem;
}

.participant__chatbox--patient .participant__chatboxMessage {
	color: var(--color-grey-900);
	background: #e9ecf4;
	text-align: right;
	text-align: left;
	margin: 0;
	justify-content: flex-start;
}
.participant__chatbox--patient .participant__chatboxHeader {
	gap: 0rem;
	justify-content: flex-start;
}

.chatTab__chatbox__form {
	height: 70px;
	/* height: fit-content; */
	display: flex;
}

.participant__chatBottomMsgBox {
	display: flex;
	justify-content: space-between;
	background: #f1f3f4;
	width: 100%;
	border-radius: 6px;
	height: 45px;
}

.participant__chatBottomMsgBox > input {
	width: 100%;
	border: none;
	outline: none;
	padding: 0.5rem;
	background: transparent;
	border-radius: 6px;
	font-size: 1rem;
}
.participant__chatBottomMsgBox > .MuiIconButton-root {
	border-radius: 0px;
}

.upload__label {
	display: flex;
	cursor: pointer;
}

.participant__chatBottomOptions {
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem;
}

.participant__chatBottomOptions--btn {
	background: inherit;
	border: 1px solid var(--Grey5);
	border-radius: 54px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.9em;

	cursor: pointer;
	position: relative;
}

.participant__chatBottomOptions--btn > p {
	color: var(--Secondary-green--dark);
	padding: 8px 12px;
}

.participant__chatBottomOptions--btn:hover {
	background: var(--Grey6);
}

/* **** */

.chatBox__welcomeView {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.chatBox__welcomeView--wrapper {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
}

.chatBox__welcomeView--wrapper > p {
	color: #000;
	font-weight: 500;
	font-size: 1.1em;
}
.chatBox__welcomeView--wrapper > span {
	color: var(--Grey2);
}
.chatBox__welcomeView--wrapper > img {
	width: 30%;
	margin-bottom: 8px;
}

/** Chat Options **/

.chatBottomOptions__popover {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	min-width: 200px;
	/* position: relative; */
}

.chatBottomOptions__popover--LabelFlex {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: space-between;
}

.chatBottomOptions__popover--LabelFlex > label {
	margin-right: 0 !important;
}
.chatBottomOptions__heading {
	font-weight: 500;
}
.chatBottomOptions__Item {
	background: var(--Grey6);
	border-radius: 4px;
	color: var(--Secondary-green--dark);
	padding: 0.4rem 1rem;
	cursor: pointer;
}
.chatBottomOptions__Item:hover {
	background: var(--Grey5);
}
/** ************ **/

.confirmAssignment__popUp {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	max-width: 250px;
	gap: 0.5rem;
}

.confirmAssignment__popUp--title {
	text-align: center;
}

.confirmAssignment__popUp--btnWrapper {
	display: flex;
	justify-content: space-around;
}

.bottomInputField__wrapper {
	/* box-shadow: rgb(100 100 111 / 15%) 0px -4px 15px; */
	box-shadow: rgb(100 100 111 / 15%) 0px 0px 15px;
	padding: 0.5rem;
}

.fileuploader__wrapper {
	display: flex;
	/* padding: 0.5rem; */

	gap: 5px;
	/* background: #efefef; */
}

.fileuploader__preview {
	padding: 0.5rem;
	background: #f1f3f4;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.3rem;
	border: 1px solid var(--Grey5);
	font-size: 0.8em;
	position: relative;
}

.fileuploader__preview .close-icon-rounded {
	cursor: pointer;
}

/* .fileuploader__progressbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: inherit;
  background: #00ba00;
} */

.fileuploader__textContent {
	display: flex;
	flex-direction: column;
}
.fileuploader__textContent > .name {
	max-width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fileuploader__textContent > .type {
	color: var(--Grey3);
}

.participant__fileUploadMessage {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.participant__fileUploadMessage > img {
	cursor: pointer;
	border: 1px solid white;
	border-radius: 50%;
	transition: 0.5s ease all;
}
.participant__fileUploadMessage > img:hover {
	background-color: var(--color-grey-900);
}
.participant__fileUploadMessage.patient > img {
	cursor: pointer;
	/* border: 1px solid #247c7c; */
	border-radius: 50%;
	transition: 0.1.5s ease all;
	/* background: var(--Secondary-green--dark); */
}
.participant__fileUploadMessage.patient > img:hover {
	background: var(--color-grey-200);
}

.file__loadingcircle {
	width: 20px;
	height: 20px;
	background: transparent;
	border: 2px solid #fff;
	border-style: dotted;
	border-radius: 50%;
	animation-name: rotate;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes rotate {
	from {
		transform: rotate(-360deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.chatTab__noMessagesAlert {
	display: flex;
	justify-content: center;
	margin-top: 4rem;
}
.chatTab__noMessagesAlert--content {
	padding: 1rem 10px;
	border-radius: 6px;
	font-size: 0.9em;
	display: flex;
	gap: 0.3rem;
	color: #000;
}
.chatTab__noMessagesAlert--content svg {
	font-size: 1.3rem;
}

/* ** style for Skeleton - used as a loader ** */
.skeleton {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 0.5rem;
}

.skeleton__box {
	border-radius: 6px;
}

.skeleton--messages .row {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.row--flexEnd {
	align-items: flex-end;
}

.row--flexStart {
	align-items: flex-start;
}
/* **************************************************** */

/* ********* CHAT LOADER STYLE*********** */
.loader-5 {
	height: 25px;
	width: 25px;
	-webkit-animation: loader-5-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-1 {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes loader-5-1 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loader-5::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: auto;
	margin: auto;
	width: 4px;
	height: 4px;
	background: #fff;
	border-radius: 50%;
	-webkit-animation: loader-5-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-2 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(20px, 0, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-2 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(20px, 0, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}
.loader-5::after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: auto;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 4px;
	height: 4px;
	background: #fff;
	border-radius: 50%;
	-webkit-animation: loader-5-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-3 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(-20px, 0, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-3 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(-20px, 0, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}
.loader-5 span {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	height: 25px;
	width: 25px;
}
.loader-5 span::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: auto;
	right: 0;
	margin: auto;
	width: 4px;
	height: 4px;
	background: #fff;
	border-radius: 50%;
	-webkit-animation: loader-5-4 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-4 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-4 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(0, 20px, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-4 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(0, 20px, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}
.loader-5 span::after {
	content: '';
	display: block;
	position: absolute;
	top: auto;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 4px;
	height: 4px;
	background: #fff;
	border-radius: 50%;
	-webkit-animation: loader-5-5 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-5 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-5 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(0, -20px, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-5 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(0, -20px, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}

/* **************************************************** */

.chat__box__date {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	gap: 1rem;
	padding: 1rem;
}

.chat__box__date--line {
	width: 100%;
	background-color: var(--color-grey-100);
	height: 2px;
}

.chat__box__date--title {
	white-space: nowrap;
	color: var(--color-grey-700);
}

@media (max-width: 1024px) {
	.chatTab__chatbox__header {
		height: 60px;
	}
	.chatTab__chatbox__form {
		height: 0px;
	}
}
