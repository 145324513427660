/* ********* Global Color styling for the full app ********* */
:root {
	--primary-green: #1ace7f;
	--primary-green--dark: #15b16d;
	--primary-green--light: #e3f6f1;
	--secondary-blue: #23254f;
	--container-grey: #f7f8fa;
	--white: #fff;
	--black: #000;
	--line-grey: #d7e0e9;

	/* Grey color palette (Stripe) */
	--color-grey-50: #f7fafc;
	--color-grey-100: #e3e8ee;
	--color-grey-dark-100: #7a8394;
	--color-grey-200: #c1c9d2;
	--color-grey-300: #a3acb9;
	--color-grey-400: #8792a2;
	--color-grey-500: #697386;
	--color-grey-600: #59617b;
	--color-grey-700: #3c4257;
	--color-grey-800: #2a2f45;
	--color-grey-900: #1a1f36;

	--main-bg-color: #f3f2ef;
	--Grey1: #333333;
	--Grey2: #4f4f4f;
	--Grey3: #828282;
	--Grey4: #bdbdbd;
	--Grey5: #e0e0e0;
	--Grey5--hover: #c5c5c5;
	--Grey6: #f2f2f2;
	--Blue: #4a9ef7;
	--Blue-hover: #1f86f4;
	--Container-grey: #f0f0f0;
	--Container-grey-hover: #d1d1cf;
	--Container-text: #707683;
	--Secondary-green: #14a6a6;
	--Secondary-green--hover: #0d7979;
	--Secondary-green--hoverLight: rgba(181, 255, 255, 0.2);
	--Secondary-green--light: #e0f0f2;
	--Secondary-green--dark: #139696;
	--line-grey: #d7e0e9;
}

body {
	margin: 0;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul,li,p{
	margin: 0;
	padding: 0;
}

.App {
	text-align: center;
}

.header {
	/* border-bottom: 1px solid var(--line-grey); */
}

.header img {
	width: 226px;
}

.center,
.center-column,
.top,
.right,
.bottom,
.left {
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-column {
	flex-direction: column;
}

.top {
	align-items: flex-start;
}
.right {
	justify-content: flex-end;
}
.bottom {
	align-items: flex-end;
}
.left {
	justify-content: flex-start;
}

.text-center {
	text-align: center;
}

.space-between {
	justify-content: space-between;
}

.gap-xs {
	gap: 8px;
}

.width-100 {
	width: 100%;
}

.fw-500 {
	font-weight: 500 !important;
}
.fw-600 {
	font-weight: 600 !important;
}

.p-xs {
	padding: 8px;
}
.p-sm {
	padding: 16px;
}
.p-smd {
	padding: 24px;
}
.p-md {
	padding: 32px;
}
.p-lg {
	padding: 48px;
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	background: #fff;
	z-index: 100;
}

.single-spaced,
.single-spaced * {
	line-height: 1;
}

@media (max-width: 1024px) {
	.desktop {
		display: none;
	}
}

.MuiStepIcon-text {
	fill: #fff !important;
}

.stepper__wrapper {
	max-width: 1440px;
	margin: auto;
	margin-top: 64px;
}

.stepper__numbers {
	max-width: 1024px;
	margin: auto;
}

.stepper__content {
	/* border: 1px solid red; */
	/* max-width: 1024px; */
	max-width: 768px;
	margin: auto;
}

.stepper__content__formFields {
	padding: 1rem 0;
}
.forms_margin {
	padding: 1rem 0;
}

.stepper__content__wrapper {
	padding-bottom: 3rem;
}

.showShadow {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.border {
	border: 1px solid red;
}

.form__btnWrapper {
	display: flex;
	padding-top: 1.5rem;
	gap: 8px;
	justify-content: flex-end;
}

/* .inputField--large {
  width: 600px !important;
} */

/* ------------- PROVIDERS ------ */
.container {
	width: 100%;
	background: var(--container-grey);
	min-height: 300px;
	border: 1px solid var(--line-grey);
	border-radius: 8px;
}

.container__header {
	display: flex;
	justify-content: space-between;
	padding: 24px 24px 8px 24px;
}

.container__listItems {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.container__listItem {
	background: #fff;
	border: 1px solid var(--line-grey);
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	padding: 1rem;
}

.container__listItemDetails {
	display: flex;
	gap: 1rem;
}

.dialogue__container {
	padding: 1.5rem !important;
}
